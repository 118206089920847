@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/Avenir-Next-Font.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Avenir Next';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold;
}

.App {
  width: 100%;
  height: 1000px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

.welcome h1 {
  text-transform: uppercase;
  padding-top: 400px;
  font-size: 72px;
  letter-spacing: 7px;
  padding-bottom: 10px;
}

.welcome h1, .welcome h2 {
  color: #fff;
}

.welcome h2, .info h2 {
  letter-spacing: 1px;
  font-size: 30px;
}

.grad1 {
  height: 50px;
  background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,255,255,1));
  margin-top: 413px;
}

.contact {
  color: rgba(177,179,178,255);
  font-size: 40px;
  padding-bottom: 100px;
}

.info h2 {
  color: rgba(177,179,178,255);
  font-size: 40px;
  padding-top: 70px;
  padding-bottom: 30px;
}

.phone-icon {
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .App {
    height: 500px;
  }

  .welcome h1 {
    padding-top: 23vw;
    font-size: 7vw;
    letter-spacing: 1vw;
    padding-bottom: 25vw;
  }
  
  .welcome h2 {
    letter-spacing: 1px;
    font-size: 4vw;
    color: rgba(177,179,178,255);
  }

  .contact {
    font-size: 5vw;
    padding-bottom: 10vw;
  }

  .info h2 {
    letter-spacing: 1px;
    font-size: 4vw;
    padding-top: 7vw;
    padding-bottom: 3vw;
  }

  .phone-icon {
    margin-right: 2vw;
  }

  .grad1 {
    display: none;
  }
}